import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_5c54f300/components/AboutBlock/AboutBlock.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_5c54f300/components/FeaturesBlock/FeaturesBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/tmp/build_5c54f300/app/[locale]/Home.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_5c54f300/components/FlightSearchForm/FlightSearchForm.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_5c54f300/components/LandingHeroBanner/LandingHeroBanner.module.scss");
;
import(/* webpackMode: "eager" */ "/tmp/build_5c54f300/node_modules/next/dist/client/image-component.js");
