"use client";

import { Button } from "@nextui-org/react";
import { useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";
import AutoSuggest from "react-autosuggest";
import {
  AiOutlineCloseCircle,
  AiOutlineMinus,
  AiOutlinePlus,
} from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { FlightTypeEnum } from "../../enum/flightType";
import styles from "./FlightSearchForm.module.scss";

const FlightSearchForm = ({ airports }: any) => {
  const t = useTranslations("form_flight");
  const [flightType, setFlightType] = useState(FlightTypeEnum.ONEWAY);
  const [flights, setFlights] = useState([
    {
      fromAirport: "",
      toAirport: "",
      departureDate: "",
      returnDate: "",
      passengers: 1,
    },
  ]);
  const [airportSuggestions, setAirportSuggestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [language, setLanguage] = useState("");

  const handleFlightTypeChange = (type: FlightTypeEnum) => {
    setFlightType(type);
    // If the user selects ONEWAY or ROUNDWAY, reset flights to a single element array
    setFlights([
      {
        fromAirport: "",
        toAirport: "",
        departureDate: "",
        returnDate: "",
        passengers: 1,
      },
    ]);
  };

  const handleAddMulticityFlight = () => {
    setFlights([
      ...flights,
      {
        fromAirport: "",
        toAirport: "",
        departureDate: "",
        returnDate: "",
        passengers: 1,
      },
    ]);
  };

  const handleChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const updatedFlights = [...flights];
    updatedFlights[index][field] = value;

    setFlights(updatedFlights);
  };

  const decreasePerson = (index: number, field: string, value: number) => {
    if (value > 0) {
      const updatedFlights = [...flights];
      updatedFlights[index] = { ...updatedFlights[index], [field]: value - 1 };
      setFlights(updatedFlights);
    }
  };

  const increasePerson = (index: number, field: string, value: number) => {
    const updatedFlights = [...flights];
    updatedFlights[index] = { ...updatedFlights[index], [field]: value + 1 };
    setFlights(updatedFlights);
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      let data = { flightType, flights };
      localStorage.setItem("quote", JSON.stringify(data));
      window.open(
        `https://www.privatefleetservices.com/${language}/estimate-price`,
        "_blank"
      );
      // router.push(`/estimate-price`, { target: "_blank" });
    } catch (error) {
      // console.error("Error submitting form: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteRow = (index: number) => {
    // Create a copy of the flights state array
    const updatedFlights = [...flights];
    // Remove the element at the given index from the copied array
    updatedFlights.splice(index, 1);
    // Set the state with the updated array
    setFlights(updatedFlights);
  };

  const getAirports = (value: string) => {
    // Find the complete airport object from the suggestions based on the selected value (name)
    const filteredAirports = airports
      .filter(
        (airport: { name: string; city: string; iata: string; icao: string }) =>
          airport.name.toLowerCase().includes(value.toLowerCase()) ||
          airport.city.toLowerCase().includes(value.toLowerCase()) ||
          airport.iata.toLowerCase().includes(value.toLowerCase()) ||
          airport.icao.toLowerCase().includes(value.toLowerCase())
      )
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    setAirportSuggestions(filteredAirports);
  };

  useEffect(() => {
    if (window.location && window?.location?.pathname.includes("/ar")) {
      document.body.dir = "rtl";
      setLanguage("ar");
    } else {
      document.body.dir = "ltr";

      setLanguage("en");
    }
  }, [language]);

  const renderFormFields = () => {
    return flights.map((flight, index) => (
      <React.Fragment key={index}>
        <div className={styles.row}>
          <AiOutlineCloseCircle
            className={styles.closeMark}
            onClick={(e) => deleteRow(index)}
          />
          <div className={styles.form_input_group}>
            <label htmlFor="fromAirport">{t("source")}</label>
            <div className={styles.input_group}>
              <CiLocationOn className={styles.icon} />

              <AutoSuggest
                suggestions={airportSuggestions}
                onSuggestionsFetchRequested={({ value }) => getAirports(value)}
                onSuggestionsClearRequested={() => setAirportSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion) => (
                  <span>
                    {suggestion.name +
                      " " +
                      `(${suggestion.iata})` +
                      `(${suggestion.icao})`}
                  </span>
                )}
                inputProps={{
                  type: "text",
                  id: "fromAirport",

                  placeholder: t("searchAirport"),
                  value: flight.fromAirport,
                  onChange: (event, { newValue }) => {
                    handleChange(index, "fromAirport", newValue);
                  },
                }}
              />
            </div>
          </div>
          <div className={styles.form_input_group}>
            <label htmlFor="toAirport">{t("destination")}</label>
            <div className={styles.input_group}>
              <CiLocationOn className={styles.icon} />

              <AutoSuggest
                suggestions={airportSuggestions}
                onSuggestionsFetchRequested={({ value }) => getAirports(value)}
                onSuggestionsClearRequested={() => setAirportSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion) => (
                  <span>
                    {suggestion.name +
                      " " +
                      `(${suggestion.iata})` +
                      `(${suggestion.icao})`}
                  </span>
                )}
                inputProps={{
                  id: "toAirport",
                  type: "text",
                  placeholder: t("searchAirport"),
                  value: flight.toAirport,
                  onChange: (event, { newValue }) => {
                    handleChange(index, "toAirport", newValue);
                  },
                }}
              />
            </div>
          </div>
          <div className={styles.form_input_group}>
            <label htmlFor="departureDate">{t("departure")}</label>
            <input
              id="departureDate"
              className={styles.input_group}
              type="datetime-local"
              value={flight.departureDate}
              onChange={(e) =>
                handleChange(index, "departureDate", e.target.value)
              }
              required
            />
          </div>
          {flightType === "ROUNDWAY" && (
            <div className={styles.form_input_group}>
              <label htmlFor="return">{t("return")}</label>
              <input
                id="return"
                type="datetime-local"
                className={styles.input_group}
                value={flight.returnDate}
                onChange={(e) =>
                  handleChange(index, "returnDate", e.target.value)
                }
                required
              />
            </div>
          )}
          <div className={styles.form_input_group}>
            <label htmlFor="passengers">{t("passengers")}</label>
            <div className={`${styles.input_group} ${styles.custom_input}`}>
              <button
                type="button"
                onClick={(e) =>
                  decreasePerson(index, "passengers", flight.passengers)
                }
                aria-label="Decrease passengers"
              >
                <AiOutlineMinus />
              </button>
              <input
                id="passengers"
                type="number"
                min="1"
                value={flight.passengers}
                onChange={(e) =>
                  handleChange(index, "passengers", parseInt(e.target.value))
                }
                required
              />
              <button
                type="button"
                onClick={(e) =>
                  increasePerson(index, "passengers", flight.passengers)
                }
                aria-label="Increase passengers"
              >
                <AiOutlinePlus />
              </button>
            </div>
          </div>
        </div>
        {flightType === "MULTICITY" && flights.length > 1 && <hr />}
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className={styles.wrapper} id="flightSearchForm">
        <div className={`container ${styles.wrapper__container}`}>
          <form onSubmit={(e) => handleSubmit(e)} className={styles.quick_form}>
            <header className={styles.quick_form__header}>
              <button
                className={`${
                  flightType === FlightTypeEnum.ONEWAY && styles.active
                } ${styles.quick_form__header__button}`}
                onClick={() => handleFlightTypeChange(FlightTypeEnum.ONEWAY)}
              >
                {t("oneway")}
              </button>
              <button
                className={`${
                  flightType === FlightTypeEnum.ROUNDWAY && styles.active
                } ${styles.quick_form__header__button}`}
                onClick={() => handleFlightTypeChange(FlightTypeEnum.ROUNDWAY)}
              >
                {t("roundway")}
              </button>
              <button
                className={`${
                  flightType === FlightTypeEnum.MULTICITY && styles.active
                } ${styles.quick_form__header__button}`}
                onClick={() => handleFlightTypeChange(FlightTypeEnum.MULTICITY)}
              >
                {t("multicity")}
              </button>
            </header>

            <div className={styles.form_body}>{renderFormFields()}</div>

            <div className={styles.btn_container}>
              {flightType === "MULTICITY" && (
                <button
                  className={`${styles.button}`}
                  type="button"
                  onClick={handleAddMulticityFlight}
                >
                  {t("add_flight")}
                </button>
              )}

              <Button
                className={styles.button}
                type="submit"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                {t("getButton")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FlightSearchForm;
